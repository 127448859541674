import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container } from "../components/Container";

export default function Privacy() {
  return (
    <Layout heroSection={false} contactFooter={false}>
      <SEO title="Thank you" />
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            padding: "20px 0",
            height: 400,
          }}
        >
          <h1>Privacy Policy</h1>
          <p>
            This website uses Google analytics to anonymously track user
            engagement. We collect data such as your location, pageviews and
            device information to allow for a better user experience.
            <br />
            We respect the browsers {"'Do Not Track'"} option and anonymize your
            ip adress. To opt out of all analytics click the button below.
            <br />
          </p>
          <a href="javascript:gaOptout();">Deactive Google Analytics</a>
        </div>
      </Container>
    </Layout>
  );
}
